import api from "../api";
import qs from "qs";


/**
 * 网点列表
 * @param param
 */
export function listStoreService(param){

  return api.get('/store/c/list?' +qs.stringify(param) ,{

  })
}

export function storeDetailService(id, param){
  return api.get(`/store/c/${id}?` +qs.stringify(param));
}
